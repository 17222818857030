import { ref } from 'vue';
import {
  FirebaseErrors, InvalidCredentials, LockedAccount, TooManyFailedAttempts, InvalidEmail, emailNotFound,
} from '@/constants';
import { checkRequireField, isValidEmail } from '@/util/validation';
import {
  buyerDetails, lockedUserStatus, updateFailedLoginAttempts, updateSuccessLogin,
} from '@/api/user';

const error = ref<any>({});

const updateLoginAttempts = async (email?:string) => {
  const params = { email };
  await updateFailedLoginAttempts(params);
};

const errorHandler = (err:string, email?:string) => {
  if (err === FirebaseErrors.InvalidPassword) {
    error.value = InvalidCredentials();
    updateLoginAttempts(email);
  } else if (err === FirebaseErrors.UserNotFoundError) {
    error.value = emailNotFound();
  } else if (err === FirebaseErrors.TooManyRequestsError) {
    error.value = TooManyFailedAttempts();
  } else if (err === FirebaseErrors.AccountLockedError) { // for acount status = inactive
    error.value = LockedAccount();
  }
  return error.value;
};

const checkValidation = (email:string, password:string) => ({
  emailMessage: checkRequireField(email, 'email'),
  passwordMessage: checkRequireField(password, 'password'),
  emailValidation: isValidEmail(email) ? '' : InvalidEmail().message,
});

const setSuccess = async (email:string) => {
  const params = { email: email.toLowerCase() };
  await updateSuccessLogin(params);
};

const lockedUserHandle = async (email:string) => {
  const params = { email: email.toLowerCase() };
  const status = await lockedUserStatus(params);
  return status;
};

export {
  errorHandler, checkValidation, setSuccess, lockedUserHandle,
};
